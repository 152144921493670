@mixin autofill {
  &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus {
    @apply bg-gray-800 border-2 border-gray-900 hover:border-primary focus:border-primary #{!important} {}
    @apply text-fill-primary-600 {}
    -webkit-box-shadow: 0 0 0px 1000px rgba(23, 23, 23, var(--tw-bg-opacity)) inset; } }

.input {
  @include autofill;
  @apply appearance-none p-4 rounded-xl focus:outline-none w-full {}
  @apply bg-gray-900 border-2 border-transparent hover:border-primary focus:border-primary {}
  @apply transition duration-150 {}
  @apply placeholder-gray-600 {}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

  &[type=number] {
    -moz-appearance: textfield; }

  &.input-icon {
    &.has-icon {
      @apply pl-12 {} } } }

.input-sm {
  @include autofill;
  @apply appearance-none p-4 py-2 rounded-xl focus:outline-none w-full {}
  @apply bg-gray-900 border-2 border-transparent hover:border-primary focus:border-primary {}
  @apply transition duration-150 {}
  @apply placeholder-gray-600 {}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

  &[type=number] {
    -moz-appearance: textfield; }

  &.input-icon {
    &.has-icon {
      @apply pl-12 {} } } }

.input-lg {
  @include autofill;
  @apply p-6 text-lg leading-none rounded-xl focus:outline-none w-full {}
  @apply bg-gray-900 border-2 border-transparent hover:border-primary focus:border-primary {}
  @apply transition duration-150 {}
  @apply placeholder-gray-600 {} }

input, textarea {
  @apply placeholder-gray-600 {} }
