.tox-tinymce {
  @apply border-0 bg-transparent #{!important} {} }

.tox .tox-edit-area__iframe {
  @apply border-0 bg-transparent #{!important} {} }

.tox-toolbar-overlord {
  @apply bg-gray-900 text-white sticky top-0 #{!important} {}
  .tox-split-button {
    @apply shadow-none #{!important} {}
    &:hover {
      @apply shadow-none bg-gray-800 #{!important} {} } }
  .tox-tbtn {
    @apply text-white m-0 #{!important} {}
    svg {
      @apply fill-current #{!important} {} }
    &:hover, &--enabled {
      @apply bg-gray-800 text-primary #{!important} {} } } }

.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
  @apply bg-transparent text-white #{!important} {}
  background: none!important; }

.tox .tox-toolbar__group {
  @apply border-gray-800 #{!important} {} }

.tox .tox-menu {
  @apply bg-gray-900 border-2 border-gray-800 #{!important} {} }

.tox .tox-collection__group {
  @apply p-0 #{!important} {} }

.tox .tox-collection--list .tox-collection__item {
  &:hover, &--enabled, &--active {
    @apply bg-gray-800 text-primary #{!important} {} } }

.tox .tox-collection__item-label {
  @apply leading-tight #{!important} {} }

.tox .tox-statusbar {
  @apply p-4 bg-transparent text-white border-0 #{!important} {}
  button, a, div {
    @apply text-white #{!important} {} } }

.tox-fullscreen {
  @apply bg-gray-900 #{!important} {}
  .tox-editor-container {
    @apply max-w-2xl m-auto {} } }
